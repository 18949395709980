import { Link } from "gatsby";
import { rgba } from "polished";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Box, Button, Section, Text, Title } from "../../components/Core";
import { device } from "../../utils";


const SectionStyled = styled(Section)`
  padding-bottom: 100px;
  padding-top: 0;
`;

const OfferWrapper = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.light, 0.05)};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const PricingMoreDetail = () => (
  <>
    <SectionStyled bg="ash">
      <Container>
        <OfferWrapper>
          <div className="d-flex flex-wrap">
            <div className="py-3 pl-5">
              <Title variant="card" fontSize="24px" color="light" mb={3}>
                This is different.
              </Title>
              <Text color="lightShade" fontSize="18px" lineHeight="28px">
                And we think that's important.
              </Text>
            </div>
            <BtnContainer>
              <Link to="/pricing">
                <Button variant="outline">More Details</Button>
              </Link>
            </BtnContainer>
          </div>
        </OfferWrapper>
      </Container>
    </SectionStyled>
  </>
);

export default PricingMoreDetail;
