import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { rgba } from "polished";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const FeatureCard = ({
  color = "primary",
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box bg="light" py="20px" px="20px" borderRadius={10} mt={1} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <i className={`${iconName}`}></i>
    </Box>
    <div>
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const ImgStyled = styled(Box)`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  height: 600px;
  width: 277px;
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const Content3 = () => {

  const data = useStaticQuery(graphql`
    query {
      phone_inspection_meters: file(relativePath: { eq: "phone_inspection_meters.png" }) {
        childImageSharp {
          fixed(height: 600) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content section 2 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0">
              <div
                className="pl-5 pl-m-3"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <ImgStyled>
                  <Img fixed={data.phone_inspection_meters.childImageSharp.fixed} />
                </ImgStyled>
              </div>
            </Col>
            <Col lg="6" md="12">
              <Box className="pt-5 pt-lg-0">
                <Box className="pl-lg-4">
                  <Title>
                    Deployment, simplified.
                  </Title>
                </Box>
                <Text>
                </Text>
                <Col className="order-lg-1 mt-5 mt-lg-0">
                  <Row>
                    <Col md="6">
                      <FeatureCard
                        color="primary"
                        iconName="fa fa-th-large"
                        title="Cross Platform"
                      >
                        Native applications on iOS and Android, phone and tablet.
                      </FeatureCard>
                    </Col>
                    <Col md="6">
                      <FeatureCard
                        color="accentOrange"
                        iconName="fa fa-tools"
                        title="Maintenance free"
                      >
                        Maintenance is on us.
                        <br />
                        Updates and new features are regularly released.
                      </FeatureCard>
                    </Col>
                    <Col md="6">
                      <FeatureCard
                        color="accentGreen"
                        iconName="fa fa-signal"
                        title="Online first"
                      >
                        Automatically retrieve the latest data as you navigate.
                        <br />
                        No waiting for updates.
                      </FeatureCard>
                    </Col>
                    <Col md="6">
                      <FeatureCard
                        color="accentBlue"
                        iconName="fa fa-mobile-alt"
                        title="Offline capable"
                      >
                        Automatically caches data locally for offline use.
                        <br />
                        Queues updates until you re-connect.
                      </FeatureCard>
                    </Col>
                  </Row>
                </Col>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content3;
