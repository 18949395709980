import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";

import {
  Title,
  Section,
  Box,
  Text,
  Button,
} from "../../components/Core";
import { device } from "../../utils";
import { Link } from "gatsby";

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => theme.colors.greyBackground};
  position: relative;
  &::after {
    content: "";
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark}!important;
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  max-width: 300px;
  margin: 30px;
  padding-left: 0;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    color: #19191b;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;
    text-align: left;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const CardPricing = styled(Box)`
  box-shadow: ${({ theme }) =>
    `0 52px 54px ${rgba(theme.colors.shadow, 0.125)}`};
  border-radius: 10px;
  background-color: #fff;
  padding-top: 30px;

  button {
    width: 100% !important;
    border-radius: 0 0 10px 10px !important;
  }
`;

const TitleSmall = styled.h4`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.52px;
  line-height: 1;
  margin-bottom: 5px;
`;

const TimePer = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.52px;
  line-height: 1;
  margin-bottom: 5px;
`;

const Pricing = () => {

  return (
    <>
      {/* <!-- Pricing section --> */}
      <SectionStyled pt="90px !important" pb="0 !important">
        <Container
          className="position-relative"
          css={`
            z-index: 1;
          `}
        >
          <Row className="justify-content-center">
            <Col md="8" lg="9">
              <div className=" text-center">
                <Title>Purchasing, simplified.</Title>
                <Text>
                  Try with no commitment.
                  <br />
                  Flexible subscription, or up-front payment.
                </Text>
              </div>
            </Col>
          </Row>
          <div className="text-center pt-5">

            <Row>
              <Col lg="4" className="mb-5">
                <CardPricing>
                  <div className="mb-4">
                    <TitleSmall>Opqo Free</TitleSmall>
                    <div className="d-flex align-items-end justify-content-center my-3">
                      <Title
                        css={`
                          font-size: 80px;
                          letter-spacing: -1.38px;
                          margin-bottom: 0 !important;
                        `}
                      >
                        Free
                      </Title>
                    </div>
                    <Text fontSize="18px">First 5 users</Text>
                    <ULStyled>
                      <li>All features and updates</li>
                      <li>Support via email</li>
                      <li>Free forever</li>
                    </ULStyled>
                  </div>
                  <Link to="/get-started">
                    <Button bg="secondary">Get Started</Button>
                  </Link>
                </CardPricing>
              </Col>
              <Col lg="4" className="mb-5">
                <CardPricing>
                  <div className="mb-4">
                    <TitleSmall>Pay-as-you-use</TitleSmall>
                    <div className="d-flex align-items-end justify-content-center my-3">
                      <Currency>$</Currency>
                      <Title
                        css={`
                          font-size: 80px;
                          letter-spacing: -1.38px;
                          margin-bottom: 0 !important;
                        `}
                      >
                        40
                      </Title>
                      <TimePer> /mo</TimePer>
                    </div>
                    <Text fontSize="18px">Per user</Text>
                    <ULStyled>
                      <li>First 5 users free</li>
                      <li>Only pay for active users</li>
                      <li>No commitment</li>
                    </ULStyled>
                  </div>
                  <Link to="/get-started">
                    <Button bg="secondary">Get Started</Button>
                  </Link>
                </CardPricing>
              </Col>
              <Col lg="4" className="mb-4">
                <CardPricing>
                  <div className="mb-4">
                    <TitleSmall>Up-front</TitleSmall>
                    <div className="d-flex align-items-end justify-content-center my-3">
                      <Currency>$</Currency>
                      <Title
                        css={`
                          font-size: 80px;
                          letter-spacing: -1.38px;
                          margin-bottom: 0 !important;
                        `}
                      >
                        440
                      </Title>
                      <TimePer>/yr</TimePer>
                    </div>
                    <Text fontSize="18px">Per user</Text>
                    <ULStyled>
                      <li>First 5 users free</li>
                      <li>Up front payment</li>
                      <li>No usage reporting</li>
                    </ULStyled>
                  </div>
                  <Link to="/get-started">
                    <Button bg="secondary">Get Started</Button>
                  </Link>
                </CardPricing>
              </Col>
            </Row>
          </div>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Pricing;
