import React from "react";
import styled from "styled-components";

import { Box } from "../../components/Core";
import { Row, Col } from "react-bootstrap";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ButtonContainer = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

const ButtonApp = styled.a`
  padding-right: 18px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }
`;

const StoreBadge = ({ store, link }) => {

  const data = useStaticQuery(graphql`
    query {
      apple_appstore_badge: file(relativePath: { eq: "apple_appstore_badge.png" }) {
        childImageSharp {
          fixed(height: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      google_play_badge: file(relativePath: { eq: "google_play_badge.png" }) {
        childImageSharp {
          fixed(height: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }

    }
  `)

  return (
    <Col className="mb-5 mb-lg-0">
      <ButtonApp href={link} target="_blank" rel="noopener noreferrer">
        <Img fixed={store === "playstore"
          ? data.google_play_badge.childImageSharp.fixed
          : data.apple_appstore_badge.childImageSharp.fixed} />
      </ButtonApp>
    </Col>
  );
};

export const AppStoreBadge = ({ ...rest }) => {
  return (
    <StoreBadge store="appstore" link="https://apps.apple.com/us/app/opqo/id1540524926" {...rest} />
  );
};

export const PlayStoreBadge = ({ ...rest }) => {
  return (
    <StoreBadge store="playstore" link="https://play.google.com/store/apps/details?id=io.sharptree.asset_management" {...rest} />
  );
};

export const StoreBadgeBar = ({ ...rest }) => {
  return (
    <ButtonContainer>
      <Row className="justify-content-center align-items-center">
        <AppStoreBadge {...rest} />
        <PlayStoreBadge {...rest} />
      </Row>
    </ButtonContainer>
  );
};

