import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Title } from "../../components/Core";
import { StoreBadgeBar } from "../../components/StoreBadge";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Box bg="dark" className="position-relative" py={[4, null, null, 5, 6]}>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7">
            <Box className="text-center">
              <Title color="light">Deploy and use today.</Title>
              <div className="d-flex flex-column align-items-center pt-3">
                <StoreBadgeBar />
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
);

export default CTA;